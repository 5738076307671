<template>
  <section>
    <page-header
      v-if="clientData && clientData.activity" 
      sticky
    >
      <title-with-client :title="clientData.activity.title" />
    </page-header>
    <workflow
      v-if="(clientData && clientData.activity)"
      :reminder="clientData.activity"
      :client-data="clientData"
      :provider-data="providerData"
      @track="track"
    />
  </section>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import PageHeader from '@/components/dashboard/PageHeader'
  import TitleWithClient from '@/views/dashboard/TitleWithClient'
  import Workflow from '@/kit/Workflow'

  export default {
    name: 'Activity',
    components: {
      'page-header': PageHeader,
      'title-with-client': TitleWithClient,
      'workflow': Workflow
    },
    data() {
      return {
        clientData: null
      }
    },
    computed: {
      ...mapGetters('clients', ['client']),
      ...mapGetters('authentication', ['providerName']),
      title() {
        return this.clientData.activity?.title || ''
      },
      providerData() {
        return {
          name: this.providerName,
          roomUrl: undefined
        }
      }
    },
    async created() {
      this.clientData = await this.getClientData()
      if(!this.clientData?.activity) {
        this.$router.push({name: 'dashboard'})
      }
    },
   
    methods: {
      ...mapActions({
        getClientData: 'clients/getClientData'
      }),
      track(data) {
        const event = data.event
        delete data.event
        this.$firebase.analytics.logEvent(event, data)
      }
    }
  }
</script>